/* eslint-disable no-console */
import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log('Service worker is active.');
        },

        registered(registration) {
            setInterval(() => {
                registration.update();
            }, 1000 * 60 * 60); // e.g. hourly checks
        },

        cached() {
            console.log('Content has been cached for offline use.');
        },

        updatefound() {
            console.log('New content is downloading.');
        },

        updated(registration) {
            document.dispatchEvent(
                new CustomEvent('swUpdated', { detail: registration }),
            );
            // window.location.reload(true);
        },

        offline() {
            console.log('No internet connection found. App is running in offline mode.');
        },

        error(error) {
            console.error('Error during service worker registration:', error);
        },
    });
}
