/* eslint-disable import/no-cycle */
import useConnection from '@/composables/useConnection';

export default ({ next }) => {
    const { isConnected } = useConnection();

    if (!isConnected.value) {
        return next({
            name: 'HomeScreen',
        });
    }

    return next();
};
