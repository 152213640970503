<template>
    <i class="ui-AppIcon" @click="$emit('click', $event)">

        <component
            :is="iconFile"
            :width="width"
            :height="height"
            :class="{ color, 'spin': spin }" />
    </i>
</template>

<script>
export default {
    name: 'UIAppIcon',

    props: {
        iconName: {
            type: String,
            required: true,
        },

        width: {
            type: Number,
            default: 20,
        },

        height: {
            type: Number,
            default: 20,
        },

        color: {
            type: String,
            default: 'primary-brand',
        },

        spin: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        iconFile() {
            /* eslint template-curly-spacing: "off", indent: "off"  */
            return () => import(`../../assets/svg/icon-${this.iconName}.svg?inline`);
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-AppIcon {

    .spin {
        animation-name: spin;
        animation-duration: 3000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(-360deg);
        }
    }
}
</style>
