/**
 * Vue i18n implementation
 * @see https://medium.com/i18n-and-l10n-resources-for-developers/the-ultimate-guide-to-vue-localization-with-vue-i18n-bf98b1d40c65
 */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import getBrowserLocale from '@/utils/getBrowserLocale';
import { supportedLocalesInclude } from '@/utils/supportedLocales';
import $http from 'axios';

Vue.use(VueI18n);

/**
 * Function to load the translated messages from the JSON files
 * @returns {Object} messages
 */
const loadLocaleMessages = () => {
    const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};

    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);

        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });

    return messages;
};

/**
 * Fucntion to get the starting locale based on user browser language
 * @returns {String} Locale string
 */
const getStartingLocale = () => {
    const browserLocale = getBrowserLocale({ countryCodeOnly: true });
    const preferredLocale = localStorage.getItem('preferred-locale');

    if (supportedLocalesInclude(preferredLocale)) {
        return preferredLocale;
    }

    if (supportedLocalesInclude(browserLocale)) {
        return browserLocale;
    }

    return process.env.VUE_APP_I18N_LOCALE || 'en';
};

// Set default API request localization header
$http.defaults.headers.common['x-localization'] = getStartingLocale();

export default new VueI18n({
    locale: getStartingLocale(),
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages(),
});
