import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import i18n from '@/plugins/i18n';
import 'dayjs/locale/nl';
import 'dayjs/locale/en';

dayjs.locale(i18n.locale);
dayjs.extend(relativeTime);

export default function useFilters() {
	/**
	 * Filter to format date string to human readable date
	 * @params {Number} date Datetime string
	 */
	const timeFromNow = (date) => {
		if (!date) {
			return null;
		}

		return dayjs(date).fromNow();
	};

	/**
	 * Filter to format curreny to readable format
	 * @params {Number} value Price value number
	 */
	const currency = (value) => {
		if (typeof value !== 'number') {
			return value;
		}

		return value.toFixed(2);

		return value.toLocaleString('nl-NL', {
			style: 'currency',
			currency: 'EUR',
		});

		// const formatter = new Intl.NumberFormat('nl-NL', {
		//     style: 'currency',
		//     currency: 'EUR',
		// });

		// return formatter.format(value);
	};

	return {
		timeFromNow,
		currency,
	};
}
