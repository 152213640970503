/* eslint-disable dot-notation */
import '@/utils/installCompositionAPI';
import Vue from 'vue';
import '@/utils/registerServiceWorker';
import PortalVue from 'portal-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VueToast from 'vue-toast-notification';
import axios from 'axios';
import App from '@/App.vue';
import router from '@/router';
import i18n from '@/plugins/i18n';
import '@/utils/validationRules';
import '@/filters';
import useConnection from '@/composables/useConnection';
import VueGtm from 'vue-gtm';
import VueMask from 'v-mask';

Vue.config.productionTip = false;

// Portal Vue
Vue.use(PortalVue);

// Vue Mask
Vue.use(VueMask);

// Vue Validate
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// Vue Toast Notification
Vue.use(VueToast, {
	position: 'top',
	duration: 4000,
});

// Google Tag Manager
Vue.use(VueGtm, {
	id: 'GTM-PBRWT34',
	vueRouter: router, // Pass the router instance to automatically sync with router (optional)
});

// Axios
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;
// axios.defaults.baseURL = '/api';
axios.defaults.headers.common['Accept'] = 'application/json, text/plain, */*';
axios.defaults.headers.common['Junea'] = process.env.VUE_APP_JUNEA_HEADER;

// If token exists set header
axios.defaults.withCredentials = true;

Vue.prototype.$http = axios;

// Dark/light mode
const preferredColorScheme = localStorage.getItem('preferred-color-scheme');

if (preferredColorScheme === 'dark') {
	document.documentElement.classList.add('mode-dark');
} else if (preferredColorScheme === 'light') {
	document.documentElement.classList.remove('mode-dark');
} else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
	document.documentElement.classList.add('mode-dark');
} else {
	document.documentElement.classList.remove('mode-dark');
}

// PWA connectivity event listener
const { handleConnection } = useConnection();
handleConnection();
window.addEventListener('online', handleConnection);
window.addEventListener('offline', handleConnection);

// Lock screen orientation
// const { orientation } = require('o9n');

// orientation.lock('portrait');

// Vue instance
new Vue({
	router,
	i18n,
	render: (h) => {
		console.log('render');
		return h(App)
	},
}).$mount('#app');
