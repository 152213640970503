/* eslint-disable import/no-cycle */
import $http from 'axios';
import router from '@/router';

class AuthenticationError extends Error {
    constructor(errorCode, message) {
        super(message);
        this.name = this.constructor.name;
        this.message = message;
        this.errorCode = errorCode;
    }
}

const UserService = {
    /**
     * Login the user and store the access token to TokenService
     *
     * @async
     * @param {String} email
     * @param {String} password
     * @returns {Object} User data
     * @throws AuthenticationError
     */
    async login(email, password) {
        try {
            await $http.get('/sanctum/csrf-cookie');

            $http.defaults.withCredentials = true;

            const response = await $http.post('/auth/login', {
                email,
                password,
            });

            return response.data.data;
        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data);
        }
    },

    /**
     * Register a new user
     *
     * @async
     * @param {Object} userData
     * @returns {Object} User data
     * @throws AuthenticationError
     */
    async createUser(userData) {
        try {
            await $http.get('/sanctum/csrf-cookie');

            $http.defaults.withCredentials = true;

            const response = await $http.post('/auth/register', {
                first_name: userData.firstName,
                last_name: userData.lastName,
                email: userData.email,
                password: userData.password,
            });

            await UserService.login(userData.email, userData.password);

            // Redirect the user to the page he first tried to visit or to the home view
            router.push({ name: 'Account' });

            return response.data.data;
        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data);
        }
    },

    /**
     * Logout the current user by removing the token from storage
     * Will also remove `Authorization Bearer <token>` header from future requests
     *
     * @returns {void}
     */
    async logout() {
        await $http.post('/auth/logout', { withCredentials: false });

        router.push({ name: 'NoAccount' });
    },

    /**
     * Get the current authenticated user
     *
     * @async
     * @returns {Object} User
     */
    async getUser() {
        try {
            const response = await $http.get('/account/profile');
            return response.data.data;
        } catch (error) {
            // If user is not authorized, logout
            if (error.response.status === 401) {
                // router.push({ name: 'HomeScreen' });
            }

            // router.push({ name: 'ServerError' });

            throw new AuthenticationError(error.response.status, error.response.data.detail);
        }
    },

    /**
     * Request eset user password based on e-mailaddress
     *
     * @async
     * @param {String} userData
     * @returns {Object} Response
     * @throws AuthenticationError
     */
    async requestResetPassword(email) {
        try {
            const response = await $http.post('/auth/request-password-reset', {
                email,
            });

            return response.data;
        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data.detail);
        }
    },
    /**
     * Reset user password
     *
     * @async
     * @param {String} userData
     * @returns {Object} Response
     * @throws AuthenticationError
     */
    async resetPassword(newPassword, passwordConfirmation) {
        try {
            const response = await $http.post('/auth/reset-password', {
                token: router.currentRoute.query.token,
                email: router.currentRoute.query.email,
                password: newPassword,
                password_confirmation: passwordConfirmation,
            });

            return response.data.data;
        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data.detail);
        }
    },
};

export default UserService;

export {
    UserService,
    AuthenticationError,
};
