export default ({ next }) => {
    const savedMachines = JSON.parse(localStorage.getItem('saved-machines'));

    if (!savedMachines || savedMachines.length === 0) {
        return next({
            name: 'NewMachine',
        });
    }

    return next({
        name: 'HomeScreen',
    });
};
