<template>
  <div id="app">
    <div class="h-full lg:max-w-lg mx-auto">

      <Transition name="router-transition" mode="out-in">
        <router-view id="router-view" />
      </Transition>
    </div>

    <AppUpdateAvailable v-if="$route.name !== 'OrderJunea'" />

    <portal-target name="drawers" multiple></portal-target>
    <portal-target name="dialogs" multiple></portal-target>
  </div>
</template>

<script>
import AppUpdateAvailable from "@/components/AppUpdateAvailable.vue";
import useOrder from "@/composables/useOrder";
import useMachine from "@/composables/useMachine";
import useAuth from "@/composables/useAuth";
import { onMounted, onBeforeMount, onBeforeUnmount } from "@vue/composition-api";

export default {
  name: "App",

  components: {
    AppUpdateAvailable,
  },

  setup(_, { root }) {
    function removeViewportCalculationEvents() {
      window.removeEventListener("resize", calculateViewportHeight);
      window.removeEventListener("orientationchange", calculateViewportHeight);
      screen?.orientation?.removeEventListener("change", calculateViewportHeight);
    }

    /**
     * Method to calculate the viewport height
     * @returns {void}
     * @see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
     */
    function calculateViewportHeight(event) {
      const hasDynamicSupport =  CSS.supports('height', '1dvh');

      if(hasDynamicSupport) {
        document.documentElement.style.setProperty("--vh", "1dvh");
        removeViewportCalculationEvents(); // Remove event listeners since the value is fixed, no need to recalculate.
        return;
      } else {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      }
    }

    // We listen to the resize event
    window.addEventListener("resize", calculateViewportHeight);
    window.addEventListener("orientationchange", calculateViewportHeight); // deprecated
    screen?.orientation?.addEventListener("change", calculateViewportHeight); // instead of orientationchange

    /**
     * Method to auto connect the last used machine
     * @returns {void}
     */
    async function autoConnectMachine() {
      const machineCode = localStorage.getItem("last-used-machine");

      // If we have a machine code, we fetch the machine data
      if (machineCode) {
        const { fetchMachineData, machine } = useMachine();
        await fetchMachineData(machineCode);

        if (
          root.$router.currentRoute.name !== "ProcessingPayment" &&
          root.$router.currentRoute.name !== "ProcessingFridgePayment" &&
          root.$router.currentRoute.name !== "NoAccount"
        ) {
          root.$router.push({
            name: machine.value.type === "fridge" ? "OrderFridge" : "OrderJunea",
          });
        }
      }
    }

    onBeforeMount(() => {
      // Get user data
      const { getUser } = useAuth();
      getUser();

      // Auto connect last used machine
      autoConnectMachine();
    });

    onMounted(() => {
      // Apply cart from local storage
      const { applyCartFromLocalStorage } = useOrder();
      applyCartFromLocalStorage();

      // Calculate viewport height
      calculateViewportHeight();
    });

    onBeforeUnmount(() => {
      // Remove event listeners
      removeViewportCalculationEvents();
    });
  },
};
</script>

<style lang="scss">
@import "./assets/styles/index.scss";

body {
  overscroll-behavior: contain; // Disable pull to reload
}

#app {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: hidden;
}

.initial-fade-enter-active,
.initial-fade-leave-active {
  transition: opacity 1s;
}

.initial-fade-enter,
.initial-fade-leave-to {
  opacity: 0;
}
</style>

