<template>
    <!-- eslint-disable max-len -->
    <transition name="dialog" appear>
        <div @click="$emit('close')" class="ui-BaseDialog fixed inset-0 z-30 flex justify-center items-center">
            <div @click.stop class="ui-BaseDialog_Inner p-4 mx-4 rounded-3xl bg-white dark:bg-night-04dp w-full">
                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'UIBaseDialog',
};
</script>

<style lang="scss">
.ui-BaseDialog {
    background: rgba(0, 0, 0, 0.6);
}

.dialog-enter-active,
.dialog-leave-active {
    transition: all 200ms linear;
    background: rgba(0, 0, 0, 0.6);

    .ui-BaseDialog_Inner {
        transition: all 200ms linear;
    }
}

.dialog-enter,
.dialog-leave-to {
    background: rgba(0, 0, 0, 0.0);

    .ui-BaseDialog_Inner {
        opacity: 0;
    }
}
</style>
