export default [
    {
        path: '/more',
        name: 'More',
        component: () => import(/* webpackChunkName: 'more' */ '@/views/MoreInfo/index.vue'),
    },
    {
        path: '/more/customer-service',
        name: 'CustomerService',
        component: () => import(/* webpackChunkName: 'more' */ '@/views/MoreInfo/CustomerService.vue'),
    },
    {
        path: '/more/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import(/* webpackChunkName: 'more' */ '@/views/MoreInfo/PrivacyPolicy.vue'),
    },
    {
        path: '/more/terms-and-conditions',
        name: 'TermsConditions',
        component: () => import(/* webpackChunkName: 'more' */ '@/views/MoreInfo/TermsConditions.vue'),
    },
];
