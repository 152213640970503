/* eslint-disable max-len, import/no-cycle */
import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import $http from 'axios';
import useAuth from '@/composables/useAuth';
import i18n from '@/plugins/i18n';
import router from '@/router';

const state = reactive({
    balance: null,
    maxBalanceValue: null,
});

export default function useWallet() {
    /**
     * Method to get the current wallet balance
     * @returns {Number} Wallet balance
     */
    const getWalletBalance = async () => {
        // if (state.balance) {
        //     return state.balance;
        // }

        // If balance was not already set, fetch new
        try {
            const balanceResponse = await $http.get('/wallet/balance');
            state.balance = balanceResponse.data.balance;
            state.maxBalanceValue = balanceResponse.data.maximum_balance ? Number(balanceResponse.data.maximum_balance) : null;
        } catch (error) {
            // If user is not authorized, logout
            if (error.response.status === 401) {
                const { logout } = useAuth();
                logout();
            }

            router.push({ name: 'ServerError' });
        }

        return state.balance;
    };

    /**
     * Method to top up the wallet balance
     */
    const topUpWalletBalance = async (amount, paymentMethod, idealBank) => {
        try {
            const response = await $http.post('/wallet/top-up', {
                amount,
                payment_method: paymentMethod,
                ...(paymentMethod === 'ideal' && { issuer: idealBank }),
                base_url: process.env.VUE_APP_BASE_URL,
            });

            localStorage.setItem('processing-payment', true);

            // Redirect to Buckaroo checkout page
            if (response.data?.url) {
                window.location.replace(response.data.url);
            }
        } catch (error) {
            // Show error notification to user
            Vue.$toast.open({
                message: i18n.t('default-error', {
                    error: error.response.statusText,
                }),
                type: 'warning',
                duration: 8000,
            });
        }
    };

    return {
        ...toRefs(state),
        getWalletBalance,
        topUpWalletBalance,
    };
}
