/* eslint-disable import/no-cycle */
import useAuth from '@/composables/useAuth';

// Do not allow user to visit login page or register page if they are logged in
export default async ({ to, next }) => {
    const { getUser, loggedIn } = useAuth();

    await getUser();

    if (loggedIn.value) {
        return next({
            name: 'Account',
        });
    }

    return next({
        query: {
            token: to.query.token,
            email: to.query.email,
        },
    });
};
