/* eslint-disable import/no-cycle */
import userIsAuthenticated from '@/router/middleware/UserIsAuthenticated';
import onlyWhenLoggedOut from '@/router/middleware/OnlyWhenLoggedOut';

export default [
    {
        path: '/account',
        name: 'Account',
        component: () => import(/* webpackChunkName: 'account' */ '@/views/UserAccount/index.vue'),
        meta: {
            middleware: [
                userIsAuthenticated,
            ],
        },
    },
    {
        path: '/account/welcome',
        name: 'NoAccount',
        component: () => import(/* webpackChunkName: 'account' */ '@/views/UserAccount/NoAccount.vue'),
        meta: {
            middleware: [
                onlyWhenLoggedOut,
            ],
        },
    },
    {
        path: '/account/edit-profile',
        name: 'EditProfile',
        component: () => import(/* webpackChunkName: 'account' */ '@/views/UserAccount/EditProfile.vue'),
        meta: {
            middleware: [
                userIsAuthenticated,
            ],
        },
    },
    {
        path: '/account/orders',
        name: 'OrderHistory',
        component: () => import(/* webpackChunkName: 'account' */ '@/views/UserAccount/OrderHistory.vue'),
        meta: {
            middleware: [
                userIsAuthenticated,
            ],
        },
    },
    {
        path: '/account/orders/:id',
        name: 'OrderHistoryDetail',
        component: () => import(/* webpackChunkName: 'account' */ '@/views/UserAccount/OrderHistoryDetail.vue'),
        meta: {
            middleware: [
                userIsAuthenticated,
            ],
        },
    },
];
