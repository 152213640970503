<template>
    <!-- eslint-disable max-len -->
    <BaseDialog v-if="false" class="AppUpdateAvailable">
        <h3 v-t="'update.title'"></h3>
        <p v-t="'update.description'" class=" text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis"></p>

        <AppButton @click="refreshApp" type="primary" size="small" width="full" class="mt-4" v-t="'update.button'" />
    </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Interface/BaseDialog.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import { reactive, toRefs } from '@vue/composition-api';

export default {
    name: 'AppUpdateAvailable',

    components: {
        BaseDialog,
        AppButton,
    },

    setup() {
        const state = reactive({
            refreshing: false,
            registration: null,
            updateExists: false,
        });

        /**
         * Stores our passed-in registration and displays update dialog
         *
         * @returns {void}
         */
        const showRefreshUI = (event) => {
            localStorage.removeItem('last-used-machine');

            state.registration = event.detail;
            state.updateExists = true;
        };

        document.addEventListener('swUpdated', showRefreshUI, {
            once: true,
        });

        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (state.refreshing) return;

                state.refreshing = true;
            });
        }

        /**
         * Trigger the message event listener inside our service
         * worker file by passing it a skipWaiting message
         *
         * @returns {void}
         */
        const refreshApp = () => {
            if (!state.registration || !state.registration.waiting) {
                // state.updateExists = false;
                return;
            }

            state.registration.waiting.postMessage('skipWaiting');
            // state.updateExists = false;

            setTimeout(() => {
                window.location.reload();
            }, 100);
        };

        return {
            ...toRefs(state),
            refreshApp,
        };
    },
};
</script>

<style lang="scss">
.AppUpdateAvailable {

    .ui-BaseDialog_Inner {
        padding: 1.25rem;
    }
}
</style>
