/* eslint-disable max-len, no-param-reassign, no-confusing-arrow, no-unused-vars, quote-props */
import Vue from 'vue';
import { reactive, toRefs, computed } from '@vue/composition-api';
import $http from 'axios';
import i18n from '@/plugins/i18n';

const state = reactive({
    code: null,
    machine: null,
    reseller: null,
    error: null,
    authInit: false,
    machineStatus: 'available',
    showAvailableMessage: false,
    showUnavailableMessage: false,
    machineOperatorData: null,
});

export default function useMachine() {
    /**
     * Method to disconnect machine and reset all state
     * @returns {void}
     */
    const disconnectMachine = () => {
        state.code = null;
        state.machine = null;
        state.error = null;

        localStorage.removeItem('last-used-machine');
    };

    /**
     * Method to save machine data to local storage after fetching
     * @returns {void}
     */
    const saveMachineToLocalStorage = (machineData) => {
        // Get already saved machines from local storage
        const savedMachines = JSON.parse(localStorage.getItem('saved-machines'));

        // Initialize empty new machines array
        let updatedSavedMachines = [];

        // Object with new machine to save
        const newMachineToSave = {
            machine_number: machineData.machine_number,
            type: machineData.type,
            last_used: new Date(),
        };

        // Check if there already are saved machines
        if (savedMachines && savedMachines.length >= 1) {
            const savedMachine = savedMachines.find((machine) => machine.machine_number === machineData.machine_number);

            // Add already present machines to new arrray
            updatedSavedMachines = [...savedMachines];

            // Check if new fetched machine is not already in local storage
            if (!savedMachine) {
                updatedSavedMachines.unshift(newMachineToSave);
            }
        } else {
            updatedSavedMachines.unshift(newMachineToSave);
        }

        localStorage.setItem('saved-machines', JSON.stringify(updatedSavedMachines));
    };

    /**
     * Method to fetch the machine data
     *
     * @param {String} machineCode Scanned/entered code of the machine
     * @returns {void}
     */
    const fetchMachineData = async (machineCode) => {
        disconnectMachine(); // Reset state before fetching

        try {
            const machineResponse = await $http.get(`/machine/${machineCode === '999999' ? 'skeps2' : machineCode}`);
            state.machine = machineResponse.data.data;
            state.code = state.machine.machine_number;

            // Save machine to local storage
            saveMachineToLocalStorage(state.machine);
            localStorage.setItem('last-used-machine', state.machine.machine_number);
        } catch (error) {
            state.error = error;

            // Show error notification to user
            Vue.$toast.open({
                message: i18n.t('order.machine-not-found-notification'),
                type: 'warning',
                duration: 5000,
            });
        }
    };

    /**
     * Get array with all slots of all shelves
     * @returns {Array} All individual slots
     */
    const machineSlots = computed(() => {
        if (!state.machine) return [];

        // Map all shelve slots into one array
        const allSlots = state.machine.shelves.map((shelve) => shelve.slots);

        // Return slots as not object nested array
        return [].concat(...allSlots);
    });

    /**
     * Get all unique products of slots that are not empty
     * @returns {Array} All individual products of filled slots
     */
    const uniqueFilledSlots = computed(() => {
        // Filter out all empty slots
        const filledSlots = machineSlots.value.length > 0
            ? machineSlots.value.filter((slot) => slot.stock > 0 && slot.product)
            : machineSlots.value;

        // Return array with all unique product objects (check id)
        // return [...new Map(filledSlots.map((item) => [item.product.id, item])).values()];

        const uniqueSlots = [];

        // Group by product ID
        const slotsGroupedByStock = filledSlots.reduce((slot, index) => {
            slot[index.product.id] = [...(slot[index.product.id] || []), index];
            return slot;
        }, {});

        const logData = [];

        // Get slot with most stock out of each group
        Object.entries(slotsGroupedByStock).forEach(([productId, slotData]) => {
            const highestStock = slotData.reduce((prev, current) => (prev.stock > current.stock) ? prev : current);
            uniqueSlots.push(highestStock);

            logData.push({
                'Product': Number(productId),
                'Slots': slotData.map((slot) => `${slot.bms_slot_reference}: ${slot.stock}`).join(' | '),
                'Highest slot': Number(highestStock.bms_slot_reference),
                'Highest stock': highestStock.stock,
            });
        });

        return uniqueSlots.sort((a, b) => (Number(a.bms_slot_reference) > Number(b.bms_slot_reference)) ? 1 : -1);
    });

    /**
     * After connecting to a machine update the last used propert
     * of the saved machine and set machine first in array
     *
     * @param {String} machineCode Machine to update
     * @returns {void}
     */
    const updateMachineLastUsed = (machineCode) => {
        const machineList = JSON.parse(localStorage.getItem('saved-machines'));
        const machineToUpdate = machineList.find((machine) => machine.machine_number === machineCode);

        if (machineToUpdate) {
            const machineIndex = machineList.indexOf(machineToUpdate);

            // Remove the machine from array
            machineList.splice(machineIndex, 1);

            // Update the last used property to current date/time
            machineToUpdate.last_used = new Date();

            // Add the machine again at the front of the array
            machineList.unshift(machineToUpdate);

            // Save to local storage again
            localStorage.setItem('saved-machines', JSON.stringify(machineList));
        }
    };

    return {
        ...toRefs(state),
        fetchMachineData,
        disconnectMachine,
        uniqueFilledSlots,
        updateMachineLastUsed,
    };
}
