/* eslint-disable import/no-cycle */
import { reactive, toRefs, computed } from '@vue/composition-api';
import router from '@/router';
import { UserService, AuthenticationError } from '@/services/user.service';
import useTapAndGo from '@/composables/useTapAndGo';

const state = reactive({
    authenticating: false,
    authenticationErrorCode: 0,
    authenticationError: '',
    loggedIn: computed(() => !!state.user),
    user: null,
});

export default function useAuth() {
    const { enabled: tapAndGoEnabled } = useTapAndGo();

    /**
     * Method to try to authenticate the user
     *
     * @async
     * @param {String} email
     * @param {String} password
     * @returns {Boolean} Login successful or not
     */
    const login = async ({ email, password }) => {
        state.authenticating = true;
        state.authenticationError = '';
        state.authenticationErrorCode = 0;

        try {
            const user = await UserService.login(email, password);
            state.authenticating = false;
            state.user = user;

            // Redirect the user to the page he first tried to visit or to the home view
            router.push(router.history.current.query.redirect || { name: 'Account' });

            return true;
        } catch (error) {
            if (error instanceof AuthenticationError) {
                state.authenticating = false;
                state.authenticationErrorCode = error.errorCode;
                state.authenticationError = error.message;
            }

            return false;
        }
    };

    /**
     * Method to logout the current user
     * @returns {void}
     */
    const logout = async () => {
        await UserService.logout();
        state.user = null;
        router.push({ name: 'NoAccount' });

        // Disable tap and go
        tapAndGoEnabled.value = false;
    };

    /**
     * Method to return the current authenticated user
     *
     * @async
     * @returns {Object} User
     */
    const getUser = async () => {
        try {
            const user = await UserService.getUser();
            state.user = user;

            return user;
        } catch (error) {
            if (error instanceof AuthenticationError) {
                state.authenticationErrorCode = error.errorCode;
                state.authenticationError = error.message;
            }

            return null;
        }
    };

    return {
        ...toRefs(state),
        login,
        logout,
        getUser,
    };
}
