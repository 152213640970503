/* eslint-disable indent */
import Vue from 'vue';
import useMachine from '@/composables/useMachine';
import $http from 'axios';
import i18n from '@/plugins/i18n';

export default async ({ next }) => {
    const { code: machineCode, machine, error } = useMachine();

    if (machineCode.value) {
        try {
            const machineResponse = await $http.get(`/machine/${machineCode.value}`);
            machine.value = machineResponse.data.data;
        } catch (err) {
            error.value = err;

            // Show error notification to user
            Vue.$toast.open({
                message: i18n.t('order.machine-not-found-notification'),
                type: 'warning',
                duration: 5000,
            });
        }
    } else {
        return next({
            name: 'HomeScreen',
        });
    }

    return next();
};
