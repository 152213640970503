/* eslint-disable import/no-cycle */
import useAuth from '@/composables/useAuth';

export default async ({ to, next }) => {
    const { getUser, loggedIn } = useAuth();

    await getUser();

    if (!loggedIn.value) {
        return next({
            name: 'NoAccount',

            // Store the full path to redirect the user to after login
            query: {
                redirect: to.fullPath,
            },
        });
    }

    return next();
};
