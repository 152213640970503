import isConnectedToMachine from '@/router/middleware/IsConnectedToMachine';
import refetchMachineData from '@/router/middleware/RefetchMachineData';
import autoConnectMachine from '@/router/middleware/AutoConnectMachine';

export default [
    {
        path: '/order/junea/',
        name: 'OrderJunea',
        component: () => import(/* webpackChunkName: 'order-junea' */ '@/views/Order/Junea.vue'),
        meta: {
            middleware: [
                isConnectedToMachine,
                refetchMachineData,
            ],
        },
    },
    {
        path: '/order/cart/',
        name: 'OrderCart',
        component: () => import(/* webpackChunkName: 'order-junea' */ '@/views/Order/Cart.vue'),
        meta: {
            middleware: [
                isConnectedToMachine,
            ],
        },
    },
    {
        path: '/order/processing',
        name: 'ProcessingPayment',
        component: () => import(/* webpackChunkName: 'order-junea' */ '@/views/Order/ProcessingPayment.vue'),
        meta: {
            middleware: [
                autoConnectMachine,
            ],
        },
    },
    {
        path: '/order/fridge/processing',
        name: 'ProcessingFridgePayment',
        component: () => import(/* webpackChunkName: 'order-junea' */ '@/views/Order/Fridge/ProcessingPayment.vue'),
        meta: {
            // middleware: [
            //     autoConnectMachine,
            // ],
        },
    },
    {
        path: '/order/fridge/',
        name: 'OrderFridge',
        component: () => import(/* webpackChunkName: 'order-junea' */ '@/views/Order/Fridge/Products.vue'),
        meta: {
            middleware: [
                isConnectedToMachine,
            ],
        },
    },
    {
        path: '/operate/drinks/',
        name: 'OperateDrinks',
        component: () => import(/* webpackChunkName: 'order-junea' */ '@/views/Operate/Drinks/Refill.vue'),
        meta: {
            middleware: [
                isConnectedToMachine,
            ],
        },
    },
];
