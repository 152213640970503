/* eslint-disable indent */
import Vue from 'vue';
import useMachine from '@/composables/useMachine';

export default async ({ next, to }) => {
    if (!to.query.machine) {
        return next();
    }

    const {
        fetchMachineData,
        error,
        authInit,
        machine,
    } = useMachine();

    await fetchMachineData(to.query.machine);

    if (to.query.mode === 'touch') {
        authInit.value = true;
    }

    // If status query param is present
    if (to.query.status) {
        switch (to.query.status) {
            case 'failed' || 'cancelled' || 'rejected':
                Vue.$toast.open({
                    message: '😅&nbsp; Something went wrong. Please try again..',
                    type: 'error',
                    duration: 5000,
                });

                break;

            case 'invalid-signature':
                Vue.$toast.open({
                    message: '😅&nbsp; Session expired. Please try again..',
                    type: 'error',
                    duration: 5000,
                });

                break;

            default:
                break;
        }
    }

    if (!error.value) {
        return next({ name: machine.value.type === 'fridge' ? 'OrderFridge' : 'OrderJunea' });
    }

    if (error.value) {
        Vue.$toast.open({
            message: '😅&nbsp; Oops.. We can\'t find that machine. Please try again..',
            type: 'error',
            duration: 5000,
        });
    }

    return next();
};
