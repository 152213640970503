import useMachine from '@/composables/useMachine';

export default ({ next }) => {
    const { code, machine } = useMachine();

    if (!code.value || !machine.value) {
        return next({
            name: 'HomeScreen',
        });
    }

    return next();
};
