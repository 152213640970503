/* eslint-disable max-len, import/no-cycle */
import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import useMachine from '@/composables/useMachine';
import useWallet from '@/composables/useWallet';
import $http from 'axios';
import i18n from '@/plugins/i18n';

const state = reactive({
    enabled: false,
    showInsufficientBalanceModal: false,
    orderProduct: null,
    orderStatus: null,
});

export default function useTapAndGo() {
    const { machine, code: machineNumber, error: machineError } = useMachine();
    const { getWalletBalance } = useWallet();

    /**
     * Method to toggle Tap&Go
     * @returns {void}
     */
    const toggleTapAndGo = () => {
        state.enabled = !state.enabled;
    };

    /**
     * Method to toggle the payment method drawer
     * @returns {void}
     */
    const toggleInsufficientBalanceModal = () => {
        state.showInsufficientBalanceModal = !state.showInsufficientBalanceModal;
    };

    /**
     * Refetch machine data to get updated stock numbers
     */
    const refetchMachineData = async () => {
        try {
            const machineResponse = await $http.get(`/machine/${machineNumber.value}`);
            machine.value = machineResponse.data.data;
        } catch (err) {
            machineError.value = err;

            // Show error notification to user
            Vue.$toast.open({
                message: i18n.t('order.machine-not-found-notification'),
                type: 'warning',
                duration: 5000,
            });
        }
    };

    /**
     * Method to submit the order to back-end
     * @returns {void}
     */
    const orderTappedProduct = async (product) => {
        state.orderProduct = product;
        state.orderStatus = 'processing';

        const orderBody = {
            machine_number: machineNumber.value,
            session_type: 'instant',
            base_url: process.env.VUE_APP_BASE_URL,
            payment_method: 'wallet',
            items: [product],
        };

        try {
            await $http.post('/order/checkout', orderBody);
            // await new Promise((resolve) => setTimeout(resolve, 2000));

            // Refetch machine data to get updated stock numbers
            await refetchMachineData();

            // Refetch wallet balance
            await getWalletBalance();

            // Order status success
            state.orderStatus = 'success';

            // Reset order status
            setTimeout(() => {
                state.orderStatus = null;
                state.orderProduct = null;
            }, 3000);
        } catch (error) {
            if (error.response.data?.error === 'insufficient-wallet-balance') {
                toggleInsufficientBalanceModal();
            } else if (error.response.status === 422 && error.response.data?.error_type === 'machine-in-use') {
                Vue.$toast.open({
                    message: i18n.t('machine-in-use-error'),
                    type: 'warning',
                    duration: 5000,
                });
            } else {
                Vue.$toast.open({
                    message: i18n.t('default-error', {
                        error: 'Server error',
                    }),
                    type: 'error',
                    duration: 5000,
                });
            }

            state.orderStatus = null;
            state.orderProduct = null;
        }
    };

    return {
        ...toRefs(state),
        toggleTapAndGo,
        toggleInsufficientBalanceModal,
        orderTappedProduct,
    };
}
