/* eslint-disable */
import { extend } from 'vee-validate';
import { regex, required, email, max_value } from 'vee-validate/dist/rules';
import i18n from '@/plugins/i18n';

extend('regex', {
    ...regex,
    message: (_, values) => i18n.t('validation.regex', values),
});

extend('required', {
    ...required,
    message: (_, values) => i18n.t('validation.required', values),
});

extend('email', {
    ...email,
    message: (_, values) => i18n.t('validation.email', values),
});

extend('max_value', {
    ...max_value,
    message: (_, values) => i18n.t('validation.max_value', values),
});

extend('date', {
    validate(value) {
        return value.match(/^(\d{1,2})-(\d{1,2})-(\d{4})$/);
    },

    message: (_, values) => i18n.t('validation.date', values),
});

extend('phone', {
    validate(value) {
        return value.match(/(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/);
    },

    message: (_, values) => i18n.t('validation.phone', values),
});

extend('min', {
    validate(value, args) {
        return value.length >= args.length;
    },

    message: (_, values) => i18n.t('validation.min', values),
    params: ['length'],
});

extend('password', {
    params: ['target'],

    validate(value, { target }) {
        return value === target;
    },

    message: (_, values) => i18n.t('validation.password-no-match', values),
});
