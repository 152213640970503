/* eslint-disable import/no-cycle */
import hasSavedMachines from '@/router/middleware/HasSavedMachines';
import autoConnectMachine from '@/router/middleware/AutoConnectMachine';
import isConnected from '@/router/middleware/IsConnected';

export default [
    {
        path: '/',
        meta: {
            middleware: [
                hasSavedMachines,
            ],
        },
    },
    {
        path: '/onboarding',
        name: 'Onboarding',
        component: () => import(/* webpackChunkName: 'start' */ '@/views/AppOnboarding.vue'),
    },
    {
        path: '/machines',
        name: 'HomeScreen',
        component: () => import(/* webpackChunkName: 'start' */ '@/views/HomeScreen.vue'),
    },
    {
        path: '/connect',
        name: 'NewMachine',
        component: () => import(/* webpackChunkName: 'start' */ '@/views/NewMachine.vue'),
        meta: {
            middleware: [
                isConnected,
                autoConnectMachine,
            ],
        },
    },
];
